import React from "react"
import Layout from "../components/Layout"
import ContentCard from "../components/ContentCard"
import { dataPlay } from "../data/DataSite"
import { graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

export default function Play({ data }) {
    return (
        <Layout currentPage="play" children={
            <>

                <section>
                    <div className="container pt-5">
                        <h1 className="text-secondary mb-3 mt-4 mt-md-0">Play</h1>
                        <div className="row">
                            {/* Display grid of projects */}
                            {
                                dataPlay.map((item) => (
                                    <div key={item.id} className="col-md-6 mt-5">
                                        <ContentCard
                                            id={item.id}
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            url={item.url}
                                            newTab={item.newTab}
                                            imgObj={getCorrectImg(data, item.id)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </>
        } />
    )
}

function getCorrectImg(data, key) {
    switch (key) {
        case 'themeableds':
            return getImage(data.image1);
        case 'newslet':
            return getImage(data.image2);
        case 'spotifyanim':
            return getImage(data.image3);
        case 'figmaplugin':
            return getImage(data.image4);
        default:
            return getImage(data.image4);
    }
}

export const playImage = graphql`
    fragment playImage on File {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "play/header-ds.png" }) {
      ...playImage
    }
    image2: file(relativePath: { eq: "play/header-newslet.png" }) {
      ...playImage
    }
    image3: file(relativePath: { eq: "play/header-spotifyanim.png" }) {
      ...playImage
    }
    image4: file(relativePath: { eq: "play/header-figmaplugin.png" }) {
        ...playImage
    }
  }
`